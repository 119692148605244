.testimonials {
  background: #1f1f1f url(/images/testimonials-bg.jpg) no-repeat center center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
}

body {
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  /* Firefox scroll bar */
  scrollbar-color: #929292 transparent;
  scrollbar-width: thin;
}
body::-webkit-scrollbar {
  width: 0.5em;
}
body::-webkit-scrollbar,
body::-webkit-scrollbar-thumb {
  overflow: visible;
  border-radius: 4px;
}
body::-webkit-scrollbar-track {
  background: none;
}

.App::-webkit-scrollbar-thumb {
  background-color: #929292;
}
